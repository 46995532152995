import { Column, Container } from '@123-front/ui-kit';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import Plus123Form, { Plus123LeadData } from '../../../components/123plus-form';
import FlexCenter from '../../../components/flex-center';
import Hero from '../../../components/hero';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import SuccessNotice from '../../../components/success-notice';

const defaultLead: Plus123LeadData = {
  company: '',
  name: '',
  email: '',
  phone: '',
};

const Plus123LandingBr: React.FC = () => {
  const [submitted, submit] = useState(false);
  const [leadInfo, setLeadInfo] = useState(defaultLead);
  const backgrounds = useStaticQuery(graphql`
    query {
      mobile: s3Object(localFile: { name: { eq: "fig_api_form-hero-mob" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      desktop: s3Object(localFile: { name: { eq: "fig_api_form-hero-dsk" } }) {
        localFile {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  `);
  return (
    <Layout gtmId="GTM-MDRH9XT">
      <SEO
        title="Alianças de Negócios. Parceiros 123 | API +123Seguro Brasil"
        siteTitle="123seguro"
        description="Torne-se um de nossos 123 Parceiros! Faça parte de nossas Alianças de Negócios e proteja seus clientes através da API +123."
      />
      <Hero
        title="BEM-VINDO A +123"
        subtitle="Proteja seus clientes e\npotencialize sua faturação"
        desktopBackground={backgrounds.desktop.localFile.childImageSharp.fluid}
        mobileBackground={backgrounds.mobile.localFile.childImageSharp.fluid}
      />
      <Container>
        <FlexCenter>
          <Column desktop={6}>
            {submitted ? (
              <SuccessNotice
                noticeText={`Em breve entraremos em contato por WhatsApp (${leadInfo.phone}) ou por e-mail (${leadInfo.email}).`}
                solicitudText="Solicitação enviada!"
                verifyText="Esses dados não são seus?"
                onRequestChange={() => submit(false)}
                changeText="Modificar"
              />
            ) : (
              <Plus123Form
                {...leadInfo}
                labelCompany="EMPRESA"
                sampleCompany="Ex.: Tech Co."
                labelName="NOME"
                sampleName="Ex.: Pedro da Silva"
                labelEmail="E-MAIL"
                sampleEmail="Ex.: nome@dominio.com"
                labelPhone="TELEFONE CELULAR"
                samplePhone="Ex.: 11956206000"
                minPhoneLength={6}
                maxPhoneLength={12}
                countryCode="br"
                errorMessage="Por favor, revise os campos com erro"
                emailErrorMessage="Informe um e-mail válido. Ex.:nome@dominio.com"
                phoneErrorMessage="Insira um telefone válido; ex.: 11956206000"
                focusEmailMessage="E-mail ao que enviaremos a informação"
                submitbutton="Solicitar informação sobre +123"
                onRegistration={(formValues) => {
                  setLeadInfo(formValues);
                  submit(true);
                }}
              />
            )}
          </Column>
        </FlexCenter>
      </Container>
    </Layout>
  );
};

export default Plus123LandingBr;
